import { SearchProxy, getPinUserCacheName, searchWordStat, BigBigWork } from "bbw-common" ;
import { checkLogin } from "@scripts/util" ;
import Axios from "axios" ;

export default {
    data: function () {
        return {
            /** 是否活跃中 */
            active: false,
            /** 搜索结果列表 **/
            items: [],
            /** 当前页数  **/
            pageNumber: 1,
            /** 每次翻页增量 */
            pageStep: 2,
            /** 每页条数 **/
            pageSize: 20,
            /** 是否在加载中 **/
            isLoading: true,
            /** 是否全部搜索完毕 **/
            isSearchEnd: false,
            /** 是否网络发生错误 **/
            isSearchError: false,
            /** 瀑布流设置 **/
            masonryConfig: {
                gridWidth: 236,
                gap: 16,
                masonryWidth: `auto`
            },
            /** 最大重试次数 */
            maxRetryTimes: 3,
            /** 统计来源 */
            resource: `其他`,
            /** axios取消函数队列 */
            cancelTokens: new Set (),
            /** 非VIP用户能查看的搜索结果数量 */
            noVipResultNumber: 75,
            /** 非VIP用户超过搜索条数则显示底部VIP遮罩 */
            noVipMaskBottom: false,
            refreshID: 0
        } ;
    },
    methods: {
        onPageLeave () {
            // 取消当页所有请求
            this.cancelTokens.forEach ( ( cancel ) => {
                cancel () ;
            } ) ;
            this.cancelTokens.clear () ;
            this.isLoading = false ;
        },
        isShow ( val ) {
            this.isToggleMultiDevice = val ;
        },
        /** 搜索 重置 **/
        resetSearch: function () {
            this.retryTimes = 0 ;
            this.isSearchError = false ;
            this.pageNumber = 1 ;
            this.isSearchEnd = false ;
            this.isLoading = true ;
            this.items = [] ;
            this.cancelTokens.forEach ( ( cancel ) => {
                cancel () ;
            } ) ;
            this.cancelTokens.clear () ;
            this.noVipMaskBottom = false ;
            console.log ( `this.$route.query?.kw`, this.$route.query?.kw ) ;
            // eslint-disable-next-line no-unused-expressions
            // 统计提到获得搜索结果后
            // this.$route.query?.kw ? searchWordStat ( {
            //     action_uid: this.$store.state.UserInfo.id,
            //     remain_vip: this.$store.state.UserInfo.vipDays, // 操作用户剩余VIP天数
            //     search_word: this.$route.query?.kw,
            //     search_type: ``,
            //     search_size: ``,
            //     search_form: ``,
            //     search_person: ``,
            //     search_sex: ``,
            //     search_age: ``,
            //     search_color: ``,
            //     search_copyright: ``,
            //     pin_from_page: `PL`,
            //     from_page_url: `https://www.${process.env.PINLITE_DOMAIN}/tupian`,
            //     action_source: `pc`
            // } ) : `` ;
        },
        /** 首屏加载 */
        async firstLoad ( ) {
            try {
                await this.loadHandle ( null, true ) ;
                const load = async () => {
                    if ( this.isSearchEnd ) return ;
                    if ( this.$refs.masonry.$el.clientHeight <= window.innerHeight ) {
                        await this.loadHandle ( null, true ) ;
                        this.refreshID && ( this.refreshID = new Date ().getTime () ) ;
                        await load () ;
                    }
                } ;
                await load () ;
                this.firstLoadReady = true ;
            } catch ( e ) {
                throw e ;
            }
        },
        /** 滚动事件 **/
        loadHandle: async function ( e?:Event, focus:boolean = false ) {
            const _self = this ;
            let end = false ;
            if ( ( !focus && this.isLoading ) || this.isSearchEnd ) return Promise.resolve ( true ) ;
            try {
                this.isLoading = true ;
                const items = [] ;
                for ( let i = _self.pageNumber ; i < _self.pageNumber + _self.pageStep ; i++ ) {
                    const item = await _self.loadMore ( i ).catch ( e => { throw e ; } ) ;
                    if ( item.length === 0 ) {
                        end = true ;
                        break ;
                    } else {
                        for ( const itemElement of item ) {
                            // 去重
                            const index = this.items.find ( existItem => existItem.id === itemElement.id ) ;
                            if ( index ) continue ;
                            items.push ( itemElement ) ;
                        }
                        _self.items.push ( ...items ) ;
                    }
                }

                if ( end ) {
                    this.isSearchEnd = true ;
                } else {
                    this.pageNumber += this.pageStep ;
                    this.retryTimes = 0 ;
                }
                this.isLoading = false ;
                // 去重后数量不足7再次调接口
                if ( !end && items.length < 7 ) {
                    console.log('1111')
                    await this.loadHandle () ;
                }
                return true ;
            } catch ( e ) {
                if ( e.message === `word_ERROR` ) return ;
                this.isLoading = false ;
                if ( !( e instanceof Axios.Cancel ) ) {
                    this.isSearchError = e ;
                    this.isSearchEnd = true ;
                }
                throw e ;
            } finally {
                this.isLoading = false ;
            }
        },
        /** 加载下一页数据 **/
        loadMore: function ( pageNumber, retry:number = 0 ) {
            // 非VIP只显示4页数据
            if ( !this.$store.getters[`UserInfo/isVip`] && this.items.length >= this.noVipResultNumber ) {
                this.noVipMaskBottom = true ;
                return Promise.resolve ( [] ) ;
            }
            if ( !this.fetchData ) {
                throw Error ( `组件内必须提供fetchData函数` ) ;
            }
            // 调用页面fetchData函数获取下一页数据
            return this.fetchData ( pageNumber, false ).then ( result => {
                const items = result ;
                if ( typeof items === `undefined` ) {
                    throw new Error ( `没有搜索结果` ) ;
                }
                if ( items.length === 0 ) {
                    return Promise.resolve ( [] ) ;
                } else {
                    return Promise.resolve ( items ) ;
                }
            } ).catch ( e => {
                // 签名不通过的或鉴黄不通过，主动取消的，直接停止加载
                if ( e.message === `SIGN_ERROR` || e.message === `word_ERROR` ) {
                    throw e ;
                } else if ( e instanceof Axios.Cancel ) {
                    // 主动取消，什么事也不做
                    throw e ;
                } else if ( retry < this.maxRetryTimes ) {
                    // 其他错误，尝试重新加载
                    // 切换一次节点试试
                    this.$store.commit ( `PinUser/switchPinNode` ) ;
                    return this.loadMore ( pageNumber, retry + 1 ) ;
                } else {
                    throw e ;
                }
            } ) ;
        },
        /** 当pinuser的cookie失效后调用 */
        async handlePinUserCrash ( type:number = 1 ) {
            this.$store.commit ( `PinUser/setName`, { type: type, name: `` } ) ;
            localStorage.removeItem ( getPinUserCacheName ( type ) ) ;
            await this.$store.dispatch ( `PinUser/FETCH_PIN_NAME`, { type: type } ).catch ( e => {
                this.$store.commit ( `PinUser/setName`, { type: type, name: `` } ) ;
                localStorage.removeItem ( getPinUserCacheName ( type ) ) ;
                this.isSearchEnd = true ;
                this.loading = false ;
                throw e ;
            } ) ;
        },
        /** 获取签名(如果签名不成功，检查是否登录) */
        async handleSign () {
            try {
                // 获取令牌， 如果出错就报错
                const [signErr, sign] = await this.$store.dispatch ( `PinUser/FETCH_SIGN` ) ;
                if ( signErr || !sign ) {
                    this.isSearchEnd = true ;
                    this.loading = false ;
                    this.isSearchError = true ;
                    await this.$store.dispatch ( `UserInfo/FETCH_CHECK_BROWSER_UNIQUE`, { source: this.source } ).then ( res => {
                        if ( res.status !== 200 || ( res.data.status + `` ) !== `200` ) {
                            this.$refs.loginAuth.onLoginFail ( true ) ;
                        }
                    } ) ;
                    throw new Error ( `签名获取失败` ) ;
                }
                return sign ;
            } catch ( e ) {
                throw e ;
            }
        }
    },
    computed: {
        /** 搜索状态 **/
        searchState: function () {
            // 报错
            if ( this.isSearchError && this.items.length === 0 ) {
                if ( this.$route.name === `home` && !this.keyword ) {
                    // 首页推荐内容特别提示
                    if ( this.isSearchError.response?.status === 403 || this.isSearchError.response?.status === 400 || this.isSearchError.response?.status === 500 ) {
                        return `HOME_ERROR` ;
                    } else {
                        return `ERROR` ;
                    }
                } else {
                    return `ERROR` ;
                }
            }
            // 加载中
            if ( !this.isSearchEnd || this.cancelTokens.length > 0 ) return `LOADING` ;
            if ( this.isSearchEnd ) {
                // 没有结果
                if ( this.items.length === 0 ) {
                    return `END_NORESULT` ;
                } else if ( this.items.length <= 20 ) {
                    return `END_LESSRESULT` ;
                } else {
                    return `END` ;
                }
            }
        },
        /** 非VIP底部遮罩是否需要显示， true:显示普通的search end; false:显示非VIP遮罩 */
        bottomState () {
            if ( !this.$store.getters[`UserInfo/userReady`] ) {
                return true ;
            } else {
                return !this.noVipMaskBottom ;
            }
        }
    },
    watch: {
        /** 代理节点变化的时候，重新取图片 */
        "$store.state.PinUser.proxyType": function ( newVal, oldVal ) {
            if ( newVal !== oldVal ) {
                this.items.forEach ( item => {
                    const proxy = SearchProxy.randomProxy ( ) ;
                    item.proxy = proxy ;
                } ) ;
                if ( this.$refs.masonry ) {
                    this.$refs.masonry.$children.forEach ( gridItem => {
                        gridItem.onItemChange () ;
                    } ) ;
                }
            }
        }
    }
} ;
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
